import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as style from "./header.module.scss"
import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdjust } from "@fortawesome/free-solid-svg-icons"

const Header = ({ activeTab, mode, modeSwitch }) => {

  const [min,setMin] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let scroll = window.scrollY;
      if(scroll > 6.25 * 16) {
          setMin(true);
      } else {
        setMin(false);
      }
  });
  }, []);

  return (
  <header className={`${mode === 'dark' ? style.dark : null} ${min ? style.min : null}`}>
    <div className={`content ${style.content}`}>
      <div className={style.logo}>
        <Link to="/">
          <StaticImage
            src="../../images/Logo.png"
            width={32}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Logo"
          />
        </Link>
      </div>
      <div className={`${style.links} hide-cursor`}>
        <Link to="/"><div className={activeTab === 'home' ? `${style.link} ${style.active}` : style.link}>Home</div></Link>
        <Link to="/projects"><div className={activeTab === 'projects' ? `${style.link} ${style.active}` : style.link}>Projects</div></Link>
        <Link to="/lab"><div className={activeTab === 'projects' ? `${style.link} ${style.active}` : style.link}>Lab</div></Link>
        <Link to="/about"><div className={activeTab === 'about' ? `${style.link} ${style.active}` : style.link}>About</div></Link>
        {/* <div className={style.toggle} onClick={() => { modeSwitch() }}>
          <FontAwesomeIcon icon={faAdjust} />
        </div> */}
      </div>
    </div>
  </header>
)}

Header.propTypes = {
  activeTab: PropTypes.string,
  mode: PropTypes.string,
}

Header.defaultProps = {
  mode: `light`,
}

export default Header
